import { initializeApp } from "firebase/app";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export const FIRESTORE_RESULTS = {
  ALREADY_EXIST: "ALREADY_EXIST",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};

export async function addPreRegistration(contact: string) {
  try {
    const preRegistrationRef = collection(firestore, "pre_registration");
    const docRef = doc(firestore, "pre_registration", contact);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return FIRESTORE_RESULTS.ALREADY_EXIST;
    } else {
      await setDoc(doc(preRegistrationRef, contact), {
        contact,
      });

      return FIRESTORE_RESULTS.SUCCESS;
    }
  } catch (err) {
    console.log(err);
    return FIRESTORE_RESULTS.FAILED;
  }
}
