import React from "react";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import { useScreenWidthContext } from "../../common/providers/ScreenWidthProvider";
import Text from "../../common/components/Text";
import image1 from "../../../assets/image/solution_1.png";
import image2 from "../../../assets/image/solution_2.png";
import image3 from "../../../assets/image/solution_3.png";

const SOLUTIONS = [
  {
    title: `한 개의 컨텐츠를\n한 번에, 여러 곳에 간편 업로드 가능`,
    summary:
      "하나의 컨텐츠를 업로드하는데 각 채널에 방문하여 반복된 작업.. 이제 클릭 한 번으로 여러 채널에 간편하게 업로드하세요. 효율적인 시간 관리가 가능합니다.",
    image: image1,
  },
  {
    title: `컨텐츠 성과,\n한 페이지로 비교 분석해드려요`,
    summary:
      "한 번의 소셜 채널 연동으로 컨텐츠 성과를 채널 별로 한 페이지에 보기 쉽게 비교 분석해드립니다. 크레센더와 함께라면 채널별 마케팅 전략을 세울 수 있어요.",
    image: image2,
  },
  {
    title: `컨텐츠 파일 관리 시간도\n아껴드릴게요`,
    summary:
      "컨텐츠 파일 관리 따로 할 필요 없어요. 크레센더에서 컨텐츠를 관리하고 정리하세요.",
    image: image3,
  },
];

function LandingSolution() {
  const { isMobile } = useScreenWidthContext();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      backgroundColor={theme.color.white}
    >
      <Flex
        flexDirection="column"
        padding={`${theme.spacing[isMobile ? 40 : 80]}px ${
          theme.spacing[20]
        }px`}
        alignItems="center"
        gap={theme.spacing[isMobile ? 40 : 60]}
        width="100%"
        maxWidth={807}
      >
        <Text typography="32B" textAlign="center">
          크레센더와 함께라면
        </Text>
        {SOLUTIONS.map((solution) => (
          <Flex
            key={solution.title}
            flexDirection={isMobile ? "column" : "row"}
            alignItems="center"
            gap={theme.spacing[40]}
          >
            <Flex flex={1}>
              <img
                src={solution.image}
                style={{ width: "100%", objectFit: "contain" }}
              />
            </Flex>
            <Flex flex={1} flexDirection="column" gap={theme.spacing[24]}>
              <Text typography="24B">{solution.title}</Text>
              <Text typography="14">{solution.summary}</Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

export default LandingSolution;
