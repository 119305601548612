import { ReactChildrenProp } from "global";
import React from "react";
import { ScrollRestoration } from "react-router-dom";
import TopNavigationBar from "./TopNavigationBar";
import Footer from "./Footer";

interface LayoutProps extends ReactChildrenProp {
  hideTopNavigationBar?: boolean;
  hideFooter?: boolean;
}

function Layout({
  children,
  hideTopNavigationBar = false,
  hideFooter = false,
}: LayoutProps) {
  return (
    <>
      <ScrollRestoration />
      {!hideTopNavigationBar && <TopNavigationBar />}
      {children}
      {!hideFooter && <Footer />}
    </>
  );
}

export default Layout;
