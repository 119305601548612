import React from "react";
import Layout from "../../common/components/Layout";
import LandingTop from "../components/LandingTop";
import LandingRegister from "../components/LandingRegister";
import LandingProblem from "../components/LandingProblem";
import LandingSolution from "../components/LandingSolution";
import LandingNavigationBar from "../components/LandingNavigationBar";
import TopNavigationBar from "../../common/components/TopNavigationBar";

function LandingContainer() {
  return (
    <Layout hideTopNavigationBar>
      <LandingNavigationBar />
      <LandingTop />
      <LandingRegister />
      <LandingProblem />
      <LandingSolution />
      <LandingRegister />
    </Layout>
  );
}

export default LandingContainer;
