import { Outlet, createBrowserRouter } from "react-router-dom";
import LandingContainer from "../../../modules/landing/container/LandingContainer";
import SignUpIntroContainer from "../../../modules/auth/signUp/signUpIntro/container/SignUpIntroContainer";
import SignUpEmailContainer from "../../../modules/auth/signUp/signUpEmail/container/SignUpEmailContainer";
import SignInContainer from "../../../modules/auth/signIn/container/SignInContainer";
import ConnectContainer from "../../../modules/connect/container/ConnectContainer";

export const SCREENS = {
  LANDING: "/",

  SIGN_UP: "/sign_up",

  SIGN_UP_INTRO: "/start",

  SIGN_UP_EMAIL: "/email",

  SIGN_IN: "/sign_in",

  FIRST_CONNECT: "/first_connect",

  CONNECT: "/connect",
};

export const router = createBrowserRouter([
  {
    element: <Outlet />,
    children: [
      {
        path: SCREENS.LANDING,
        element: <LandingContainer />,
      },

      {
        path: SCREENS.SIGN_UP,
        element: <Outlet />,
        children: [
          {
            path: SCREENS.SIGN_UP + SCREENS.SIGN_UP_INTRO,
            element: <SignUpIntroContainer />,
          },

          {
            path: SCREENS.SIGN_UP + SCREENS.SIGN_UP_EMAIL,
            element: <SignUpEmailContainer />,
          },
        ],
      },

      {
        path: SCREENS.SIGN_IN,
        element: <SignInContainer />,
      },

      {
        path: SCREENS.FIRST_CONNECT,
        element: <ConnectContainer />,
      },

      {
        path: SCREENS.CONNECT,
        element: <ConnectContainer />,
      },
    ],
  },
]);
