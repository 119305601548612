import React, { useMemo } from "react";
import Layout from "../../common/components/Layout";
import Flex from "../../common/components/Flex";
import useFullHeight from "../../common/hooks/useFullHeight";
import theme from "../../../lib/theme";
import { useLocation } from "react-router-dom";
import Text from "../../common/components/Text";
import Grid from "../../common/components/Grid";
import { useScreenWidthContext } from "../../common/providers/ScreenWidthProvider";
import youtubeIcon from "../../../assets/icon/youtube.svg";
import facebookIcon from "../../../assets/icon/facebook.svg";
import instagramIcon from "../../../assets/icon/instagram.svg";
import tiktokIcon from "../../../assets/icon/tiktok.svg";
import SNSConnectButton from "../../common/components/SNSConnectButton";
import Button from "../../common/components/Button";

function ConnectContainer() {
  const location = useLocation();

  const { isMobile } = useScreenWidthContext();

  const {
    refs: { wrapperRef },
    models: { height },
  } = useFullHeight();

  const isFirst = location?.pathname?.includes("first");

  const options = useMemo(
    () => [
      {
        icon: youtubeIcon,
        mainText: "유투브",
        subText: "Youtube",
      },
      {
        icon: facebookIcon,
        mainText: "페이스북",
        subText: "Facebook",
      },
      {
        icon: instagramIcon,
        mainText: "인스타그램",
        subText: "Instagram",
      },
      {
        icon: tiktokIcon,
        mainText: "틱톡",
        subText: "Tiktok",
      },
    ],
    []
  );

  return (
    <Layout hideFooter>
      <div
        ref={wrapperRef}
        style={{ height, backgroundColor: theme.color.background }}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Flex
            flexDirection="column"
            gap={theme.spacing[24]}
            backgroundColor={isFirst || isMobile ? "transparent" : "white"}
            alignItems="center"
            justifyContent="center"
            padding={
              isFirst
                ? isMobile
                  ? theme.spacing[40]
                  : 0
                : `${theme.spacing[40]}px ${
                    theme.spacing[isMobile ? 40 : 80]
                  }px`
            }
            width="100%"
            maxWidth={isFirst ? 508 : 501}
            borderRadius={4}
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              gap={theme.spacing[16]}
            >
              <Text typography="24B" textAlign="center">
                {`반갑습니다 : )\n업로드를 위해 계정 연동을 해주세요!`}
              </Text>
              <Text typography="14">
                지금 아니더라도 언제든지 연동이 가능합니다
              </Text>
            </Flex>
            <Grid
              width="100%"
              gap={theme.spacing[8]}
              gridTemplateColumns={
                isFirst
                  ? isMobile
                    ? "repeat(2, 1fr)"
                    : "repeat(4, 1fr)"
                  : "1fr"
              }
            >
              {options?.map((option) => (
                <SNSConnectButton
                  {...option}
                  isVertical={isFirst}
                  key={option.mainText}
                />
              ))}
            </Grid>
            <Text typography="14">
              걱정하지 마세요! 계정 연동만 되는거라 정보를 알 수 없음
            </Text>
            {isFirst && (
              <Flex width={221}>
                <Button
                  fullWidth
                  text="나중에하기"
                  variant="outline"
                  bgColor="white"
                  borderColor="gray4"
                  size="sm"
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </div>
    </Layout>
  );
}

export default ConnectContainer;
