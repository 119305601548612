import React, { useMemo } from "react";
import useFullHeight from "../../../common/hooks/useFullHeight";
import Layout from "../../../common/components/Layout";
import theme from "../../../../lib/theme";
import Flex from "../../../common/components/Flex";
import Text from "../../../common/components/Text";
import SocialAuthButton from "../../common/components/SocialAuthButton";
import facebookIcon from "../../../../assets/icon/facebook.svg";
import googleIcon from "../../../../assets/icon/google_logo.svg";
import useEmailAuth from "../../common/hooks/useEmailAuth";
import Input from "../../../common/components/Input";

import eyeIcon from "../../../../assets/icon/eye_fill.svg";
import eyeOffIcon from "../../../../assets/icon/eye_off_fill.svg";
import Button from "../../../common/components/Button";

function SignInContainer() {
  const {
    refs: { wrapperRef },
    models: { height },
  } = useFullHeight();

  const {
    refs: { emailInputRef, passwordInputRef },
    models: { state },
    operations: {
      onInputChange,
      toggleShowPassword,
      onKeyDown,
      onFocus,
      onBlur,
      isSubmitDisabled,
      onSubmit,
    },
  } = useEmailAuth();

  const { email, emailError, password, passwordError, showPassword, error } =
    state;

  const memoizedEmailInput = useMemo(
    () => (
      <Input
        label="이메일"
        labelTypography="14B"
        placeholder="이메일 입력"
        autoCapitalize="false"
        ref={emailInputRef}
        type="email"
        inputMode="email"
        value={email}
        errorMessage={emailError}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "email")
        }
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
          onKeyDown(e, "email")
        }
        onFocus={() => onFocus("email")}
        onBlur={() => onBlur("email")}
      />
    ),
    [email, emailError, error]
  );

  const memoizedPasswordInput = useMemo(
    () => (
      <Input
        label="비밀번호"
        labelTypography="14B"
        placeholder="8자 이상"
        autoCapitalize="false"
        ref={passwordInputRef}
        type={showPassword ? "text" : "password"}
        value={password}
        errorMessage={passwordError}
        rightIcon={showPassword ? eyeOffIcon : eyeIcon}
        onIconPress={() => toggleShowPassword(!showPassword)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "password")
        }
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
          onKeyDown(e, "password")
        }
        onFocus={() => onFocus("password")}
        onBlur={() => onBlur("password")}
      />
    ),
    [password, passwordError, showPassword, error]
  );

  const memoizedSubmitButton = useMemo(
    () => (
      <Button
        fullWidth
        text="로그인"
        bgColor="primary"
        disabled={isSubmitDisabled()}
        onClick={onSubmit}
      />
    ),
    [isSubmitDisabled()]
  );

  return (
    <Layout hideFooter>
      <div
        ref={wrapperRef}
        style={{ height, backgroundColor: theme.color.background }}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={theme.spacing[40]}
          width="100%"
          maxWidth={320}
          margin="0px auto"
          height="100%"
        >
          <Text textAlign="center" typography="24B">{`로그인`}</Text>

          <Flex
            width="100%"
            flexDirection="column"
            alignItems="center"
            gap={theme.spacing[16]}
          >
            <SocialAuthButton
              icon={googleIcon}
              text="구글 계정으로 시작"
              bgColor="white"
              fontColor="black"
            />
            <SocialAuthButton
              icon={facebookIcon}
              text="페이스북 계정으로 시작"
              bgColor="#1877f2"
              fontColor="white"
            />
            <Text
              typography="14B"
              textAlign="center"
              marginTop={theme.spacing[8]}
            >
              {`💡 소셜 로그인으로 시작하시면\n따로 연동없이 바로 크레센더를 이용할 수 있어요`}
            </Text>
          </Flex>

          <Flex
            position="relative"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                top: "50%",
                transform: "translateY(-50%)",
                height: 1,
                width: "100%",
                backgroundColor: theme.color.gray2,
                zIndex: 0,
              }}
            />
            <Text
              typography="14"
              color={theme.color.gray2}
              zIndex={2}
              backgroundColor={theme.color.background}
              padding={`0px ${theme.spacing[8]}px`}
            >
              또는
            </Text>
          </Flex>

          <Flex width="100%" flexDirection="column" gap={theme.spacing[24]}>
            {memoizedEmailInput}
            {memoizedPasswordInput}
          </Flex>

          {memoizedSubmitButton}
        </Flex>
      </div>
    </Layout>
  );
}

export default SignInContainer;
