import React from "react";
import Flex from "./Flex";
import theme from "../../../lib/theme";
import Text from "./Text";

function Footer() {
  return (
    <Flex
      flexDirection="column"
      backgroundColor={theme.color.background50}
      alignItems="center"
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding={`${theme.spacing[40]}px ${theme.spacing[20]}px`}
      >
        <Text typography="12" textAlign="center">
          @2024 crescender
        </Text>
      </Flex>
    </Flex>
  );
}

export default Footer;
