import React, { useState } from "react";

import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import Text from "../../common/components/Text";
import pdfIcon from "../../../assets/icon/pdf.png";
import Input from "../../common/components/Input";
import Button from "../../common/components/Button";
import { FIRESTORE_RESULTS, addPreRegistration } from "../../../lib/firebase";
import { toast } from "react-toastify";
import { ALT_TEXT } from "../../../utils/constants";

function LandingRegister() {
  const [contact, setContact] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);

  function onContactChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    setContact(value);
  }

  async function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      if (isRegistered) return;
      if (!contact?.length) return;

      await onSubmit();
    }
  }

  async function onSubmit() {
    if (isRegistered) return;
    if (!contact?.length) return;

    const result = await addPreRegistration(contact);

    switch (result) {
      case FIRESTORE_RESULTS.ALREADY_EXIST: {
        toast("이미 등록하셨습니다!");

        setContact("");

        break;
      }

      case FIRESTORE_RESULTS.FAILED: {
        toast("잠시 후 다시 시도해 주세요");

        break;
      }

      default: {
        toast("사전 등록이 완료되었습니다!");

        setContact("");

        setIsRegistered(true);

        break;
      }
    }
  }

  return (
    <Flex
      flexDirection="column"
      backgroundColor={theme.color.black}
      padding={`${theme.spacing[40]}px ${theme.spacing[20]}px`}
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        gap={theme.spacing[24]}
        marginBottom={theme.spacing[40]}
      >
        <Text typography="24B" color={theme.color.white} textAlign="center">
          {`크레센더 사전 등록하고\n무료 혜택 받으세요`}
        </Text>

        <img src={pdfIcon} style={{ width: 60, height: 60 }} alt={ALT_TEXT} />

        <Text typography="16B" color={theme.color.white} textAlign="center">
          {`· 마케팅에 도움 되는 마케팅 용어집 & 마케팅 사례 케이스 스터디 PDF 파일\n· 크레센더 출시 후 무료 혜택 제공`}
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        width="100%"
        maxWidth={433}
        gap={theme.spacing[8]}
      >
        {!isRegistered && (
          <Input
            placeholder="이메일 또는 연락처"
            value={contact}
            onChange={onContactChange}
            onKeyDown={onKeyDown}
          />
        )}
        <Button
          text={isRegistered ? "사전등록성공!" : "사전등록하기"}
          fullWidth={isRegistered}
          size="sm"
          isGradient
          fontColor="white"
          onClick={onSubmit}
        />
      </Flex>
    </Flex>
  );
}

export default LandingRegister;
