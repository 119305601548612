import React, { useMemo } from "react";
import Layout from "../../../../common/components/Layout";
import useFullHeight from "../../../../common/hooks/useFullHeight";
import theme from "../../../../../lib/theme";
import Flex from "../../../../common/components/Flex";
import Text from "../../../../common/components/Text";
import useEmailAuth from "../../../common/hooks/useEmailAuth";
import Input from "../../../../common/components/Input";

import eyeIcon from "../../../../../assets/icon/eye_fill.svg";
import eyeOffIcon from "../../../../../assets/icon/eye_off_fill.svg";
import Button from "../../../../common/components/Button";

function SignUpEmailContainer() {
  const {
    refs: { wrapperRef },
    models: { height },
  } = useFullHeight();

  const {
    refs: { emailInputRef, passwordInputRef },
    models: { state },
    operations: {
      onInputChange,
      toggleShowPassword,
      onKeyDown,
      onFocus,
      onBlur,
      isSubmitDisabled,
      onSubmit,
    },
  } = useEmailAuth();

  const { email, emailError, password, passwordError, showPassword, error } =
    state;

  const memoizedEmailInput = useMemo(
    () => (
      <Input
        label="이메일"
        labelTypography="14B"
        placeholder="이메일 입력"
        autoFocus
        autoCapitalize="false"
        ref={emailInputRef}
        type="email"
        inputMode="email"
        value={email}
        errorMessage={emailError}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "email")
        }
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
          onKeyDown(e, "email")
        }
        onFocus={() => onFocus("email")}
        onBlur={() => onBlur("email")}
      />
    ),
    [email, emailError, error]
  );

  const memoizedPasswordInput = useMemo(
    () => (
      <Input
        label="비밀번호"
        labelTypography="14B"
        placeholder="8자 이상"
        autoCapitalize="false"
        ref={passwordInputRef}
        type={showPassword ? "text" : "password"}
        value={password}
        errorMessage={passwordError}
        rightIcon={showPassword ? eyeOffIcon : eyeIcon}
        onIconPress={() => toggleShowPassword(!showPassword)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "password")
        }
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
          onKeyDown(e, "password")
        }
        onFocus={() => onFocus("password")}
        onBlur={() => onBlur("password")}
      />
    ),
    [password, passwordError, showPassword, error]
  );

  const memoizedSubmitButton = useMemo(
    () => (
      <Button
        fullWidth
        text="회원가입"
        bgColor="primary"
        disabled={isSubmitDisabled()}
        onClick={onSubmit}
      />
    ),
    [isSubmitDisabled()]
  );

  return (
    <Layout hideFooter>
      <div
        ref={wrapperRef}
        style={{ height, backgroundColor: theme.color.background }}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={theme.spacing[40]}
          width="100%"
          maxWidth={320}
          margin="0px auto"
          height="100%"
        >
          <Text textAlign="center" typography="24B">{`회원가입`}</Text>

          <Flex width="100%" flexDirection="column" gap={theme.spacing[24]}>
            {memoizedEmailInput}
            {memoizedPasswordInput}
          </Flex>

          {memoizedSubmitButton}
        </Flex>
      </div>
    </Layout>
  );
}

export default SignUpEmailContainer;
