import React from "react";
import Flex from "./Flex";
import theme from "../../../lib/theme";
import { ALT_TEXT } from "../../../utils/constants";
import Text from "./Text";
import Button from "./Button";

interface SNSConnectButtonProps {
  icon?: string;
  mainText?: string;
  subText?: string;
  buttonText?: string;
  disabled?: boolean;
  isVertical?: boolean;
  onClick?: () => void;
}

function SNSConnectButton({
  icon,
  mainText,
  subText,
  buttonText,
  disabled,
  isVertical = false,
  onClick,
}: SNSConnectButtonProps) {
  if (isVertical) {
    return (
      <Flex
        padding={`${theme.spacing[32]}px ${theme.spacing[16]}px`}
        border={`1px solid ${theme.color.gray4}`}
        borderRadius={8}
        flexDirection="column"
        alignItems="center"
        gap={theme.spacing[4]}
        cursor="pointer"
        minWidth={121}
        backgroundColor={theme.color.white}
        onClick={onClick}
      >
        {!!icon && (
          <img
            src={icon}
            style={{ width: 24, height: 24 }}
            alt={mainText || ALT_TEXT}
          />
        )}
        {!!mainText && <Text typography="16B">{mainText}</Text>}
        {!!subText && (
          <Text typography="11" color={theme.color.gray1}>
            {subText}
          </Text>
        )}
      </Flex>
    );
  } else {
    return (
      <Flex
        alignItems="center"
        justifyContent="space-between"
        padding={`${theme.spacing[12]}px ${theme.spacing[16]}px`}
        border={`1px solid ${theme.color.gray4}`}
        borderRadius={8}
        width="100%"
        backgroundColor="white"
      >
        <Flex alignItems="center" gap={theme.spacing[8]} flex={1}>
          {!!icon && (
            <img
              src={icon}
              style={{ width: 24, height: 24 }}
              alt={mainText || ALT_TEXT}
            />
          )}
          <Flex flexDirection="column">
            {!!mainText && <Text typography="16B">{mainText}</Text>}
            {!!subText && (
              <Text typography="11" color={theme.color.gray1}>
                {subText}
              </Text>
            )}
          </Flex>
        </Flex>

        <Button
          text={buttonText || "연결하기"}
          size="xs"
          disabled={disabled}
          onClick={onClick}
        />
      </Flex>
    );
  }
}

export default SNSConnectButton;
