import React from "react";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import Text from "../../common/components/Text";
import problem1 from "../../../assets/image/problem_1.png";
import problem2 from "../../../assets/image/problem_2.png";
import Grid from "../../common/components/Grid";
import { useScreenWidthContext } from "../../common/providers/ScreenWidthProvider";
import { ALT_TEXT } from "../../../utils/constants";

const PROBLEMS = [
  {
    title: "관리해야 할 채널이 너무 많아요 🥲",
    summary: "일일이 업로드 하는 데만 해도 시간이 많이 걸려요",
    image: problem1,
  },
  {
    title: "컨텐츠 분석, 보기만 해도 복잡해요 🥲",
    summary: "따로 공부할 시간 없는데 어떻게 봐야 하는 거죠?",
    image: problem2,
  },
];

function LandingProblem() {
  const { isMobile } = useScreenWidthContext();

  return (
    <Flex
      flexDirection="column"
      backgroundColor={theme.color.tableHeader}
      alignItems="center"
    >
      <Flex
        width="100%"
        maxWidth={1080}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding={`${theme.spacing[40]}px ${theme.spacing[20]}px`}
        gap={theme.spacing[20]}
      >
        <Text
          typography="24B"
          textAlign="center"
        >{`이런 어려움,\n겪고 계신가요?`}</Text>
        <Grid
          gridTemplateColumns={isMobile ? "1fr" : "repeat(2, 1fr)"}
          gap={theme.spacing[20]}
          width="100%"
        >
          {PROBLEMS.map((problem) => (
            <Flex
              key={problem.title}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap={theme.spacing[16]}
              backgroundColor={theme.color.white}
              padding={theme.spacing[24]}
              borderRadius={8}
            >
              <Flex
                flexDirection="column"
                alignItems="center"
                gap={theme.spacing[4]}
              >
                <Text typography="24B" textAlign="center">
                  {problem.title}
                </Text>
                <Text typography="14" textAlign="center">
                  {problem.summary}
                </Text>
              </Flex>
              <img
                src={problem.image}
                style={{ width: "100%", objectFit: "contain", maxWidth: 341 }}
                alt={ALT_TEXT}
              />
            </Flex>
          ))}
        </Grid>
      </Flex>
    </Flex>
  );
}

export default LandingProblem;
