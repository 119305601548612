import React from "react";

import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import { useScreenWidthContext } from "../../common/providers/ScreenWidthProvider";
import Text from "../../common/components/Text";
import imgMain from "../../../assets/image/img_main.svg";
import { ALT_TEXT } from "../../../utils/constants";

function LandingTop() {
  const { isMobile } = useScreenWidthContext();
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      paddingTop={theme.spacing[isMobile ? 40 : 80]}
      backgroundColor={theme.color.background50}
    >
      <Text
        textAlign="center"
        typography="40B"
        mobileTypography="28B"
        paddingLeft={theme.spacing[20]}
        paddingRight={theme.spacing[20]}
      >
        {`한번의 클릭으로\n컨텐츠 업로드부터 분석까지\n크레센더로 해결하세요`}
      </Text>

      <img
        src={imgMain}
        style={{
          objectFit: "contain",
          height: isMobile ? 100 : 228,
          margin: `${theme.spacing[isMobile ? 40 : 80]}px 0px`,
          paddingLeft: theme.spacing[20],
          paddingRight: theme.spacing[20],
        }}
        alt={ALT_TEXT}
      />

      <Text
        typography="16"
        textAlign="center"
        backgroundColor={theme.color.tableHeader}
        padding={`${theme.spacing[isMobile ? 24 : 60]}px 0px`}
        paddingLeft={theme.spacing[20]}
        paddingRight={theme.spacing[20]}
        width="100%"
      >
        {`관리하기에는 너무 많은 SNS 채널\n일일이 업로드하고 채널별 반응을 찾아보는 것, 번거롭지 않나요?\n\n`}
        <strong>
          {`원클릭 컨텐츠 관리 솔루션,\n크레센더가 여러분의 마케팅 채널을 통합 관리해드립니다.`}
        </strong>
      </Text>
    </Flex>
  );
}

export default LandingTop;
