import React from "react";
import Button from "../../../common/components/Button";
import { Color } from "theme";

interface SocialAuthButtonProps {
  icon?: string;
  text?: string;
  bgColor?: keyof Color | "transparent" | string;
  fontColor?: keyof Color;
  onClick?: () => void;
}

function SocialAuthButton({
  icon,
  text,
  bgColor,
  fontColor,
  onClick,
}: SocialAuthButtonProps) {
  return (
    <Button
      fullWidth
      text={text}
      leftIcon={icon}
      bgColor={bgColor}
      fontColor={fontColor}
      onClick={onClick}
    />
  );
}

export default SocialAuthButton;
