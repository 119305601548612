import React from "react";
import Flex from "./Flex";
import theme from "../../../lib/theme";
import { Link } from "react-router-dom";
import { SCREENS } from "../../../lib/route/utils/router";
import logo from "../../../assets/image/logo.svg";
import { useScreenWidthContext } from "../providers/ScreenWidthProvider";
import { ALT_TEXT } from "../../../utils/constants";
import Button from "./Button";

function TopNavigationBar() {
  const { isMobile } = useScreenWidthContext();

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: theme.color.black,
        position: "sticky",
        top: 0,
        zIndex: 100,
      }}
    >
      <Flex
        width="100%"
        maxWidth={1600}
        padding={`${theme.spacing[16]}px ${
          theme.spacing[isMobile ? 20 : 40]
        }px`}
        alignItems="center"
        justifyContent="space-between"
      >
        <Link to={SCREENS.LANDING}>
          <img src={logo} style={{ width: 173, height: 24 }} alt={ALT_TEXT} />
        </Link>

        <Flex alignItems="center" gap={theme.spacing[16]}>
          <Link to={SCREENS.SIGN_IN}>
            <Button
              size="md"
              text="로그인"
              variant="outline"
              bgColor="transparent"
              fontColor="white"
              borderColor="white"
            />
          </Link>
          <Link to={SCREENS.SIGN_UP + SCREENS.SIGN_UP_INTRO}>
            <Button
              size="md"
              text="무료로 시작하기"
              isGradient
              fontColor="white"
            />
          </Link>
        </Flex>
      </Flex>
    </div>
  );
}

export default TopNavigationBar;
