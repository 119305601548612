import { useEffect, useRef, useState } from "react";
import { useScreenWidthContext } from "../providers/ScreenWidthProvider";

function useFullHeight() {
  const { screenHeight } = useScreenWidthContext();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState<string | number>("100%");

  useEffect(() => {
    if (wrapperRef.current) {
      setHeight(`calc(100vh - ${wrapperRef?.current?.offsetTop}px)`);
    }
  }, [screenHeight, wrapperRef?.current]);

  return {
    refs: {
      wrapperRef,
    },
    models: {
      height,
    },
  };
}

export default useFullHeight;
