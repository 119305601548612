import React from "react";
import theme from "../../../lib/theme";
import { Link } from "react-router-dom";
import { SCREENS } from "../../../lib/route/utils/router";
import logo from "../../../assets/image/logo.svg";
import { useScreenWidthContext } from "../../common/providers/ScreenWidthProvider";
import Flex from "../../common/components/Flex";
import { ALT_TEXT } from "../../../utils/constants";
import Button from "../../common/components/Button";
import linkIcon from "../../../assets/icon/link_external.svg";

function LandingNavigationBar() {
  const { isMobile } = useScreenWidthContext();
  return (
    <Flex
      width="100%"
      backgroundColor={theme.color.black}
      position="sticky"
      top={0}
      zIndex={100}
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        width="100%"
        maxWidth={1600}
        padding={`${theme.spacing[16]}px ${
          theme.spacing[isMobile ? 20 : 40]
        }px`}
        alignItems="center"
        justifyContent="space-between"
      >
        <Link to={SCREENS.LANDING}>
          <img src={logo} style={{ width: 173, height: 24 }} alt={ALT_TEXT} />
        </Link>
        <a href="https://blog.naver.com/peperu_opepe" target="_blank">
          <Button size="md" leftIcon={linkIcon} text="공식 블로그" />
        </a>
      </Flex>
    </Flex>
  );
}

export default LandingNavigationBar;
