import React, { useRef } from "react";
import Layout from "../../../../common/components/Layout";
import Flex from "../../../../common/components/Flex";
import theme from "../../../../../lib/theme";
import Text from "../../../../common/components/Text";
import SocialAuthButton from "../../../common/components/SocialAuthButton";
import facebookIcon from "../../../../../assets/icon/facebook.svg";
import googleIcon from "../../../../../assets/icon/google_logo.svg";
import Button from "../../../../common/components/Button";
import useFullHeight from "../../../../common/hooks/useFullHeight";
import { Link } from "react-router-dom";
import { SCREENS } from "../../../../../lib/route/utils/router";

function SignUpIntroContainer() {
  const {
    refs: { wrapperRef },
    models: { height },
  } = useFullHeight();

  return (
    <Layout hideFooter>
      <div
        ref={wrapperRef}
        style={{ height, backgroundColor: theme.color.background }}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={theme.spacing[40]}
          width="100%"
          maxWidth={320}
          margin="0px auto"
          height="100%"
        >
          <Text
            textAlign="center"
            typography="24B"
          >{`한번에 업로드\n한눈에 컨텐츠 관리`}</Text>

          <Flex
            width="100%"
            flexDirection="column"
            alignItems="center"
            gap={theme.spacing[16]}
          >
            <SocialAuthButton
              icon={googleIcon}
              text="구글 계정으로 시작"
              bgColor="white"
              fontColor="black"
            />
            <SocialAuthButton
              icon={facebookIcon}
              text="페이스북 계정으로 시작"
              bgColor="#1877f2"
              fontColor="white"
            />
            <Text
              typography="14B"
              textAlign="center"
              marginTop={theme.spacing[8]}
            >
              {`💡 소셜 로그인으로 시작하시면\n따로 연동없이 바로 크레센더를 이용할 수 있어요`}
            </Text>
          </Flex>

          <Flex
            position="relative"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                top: "50%",
                transform: "translateY(-50%)",
                height: 1,
                width: "100%",
                backgroundColor: theme.color.gray2,
                zIndex: 0,
              }}
            />
            <Text
              typography="14"
              color={theme.color.gray2}
              zIndex={2}
              backgroundColor={theme.color.background}
              padding={`0px ${theme.spacing[8]}px`}
            >
              또는
            </Text>
          </Flex>

          <Flex width="100%" flexDirection="column" gap={theme.spacing[24]}>
            <Link to={SCREENS.SIGN_UP + SCREENS.SIGN_UP_EMAIL}>
              <Button text="이메일로 시작하기" fullWidth bgColor="primary" />
            </Link>
            <Text typography="14" textAlign="center">
              이미 계정이 있어요
              <Link to={SCREENS.SIGN_IN}>
                <strong> 로그인 </strong>
              </Link>
            </Text>
          </Flex>

          <Text typography="11" color={theme.color.gray1} textAlign="center">
            {`로그인을 하시면 개인 정보 보호 정책 및\n서비스 약관에 동의하시는 걸로 간주됩니다.`}
          </Text>
        </Flex>
      </div>
    </Layout>
  );
}

export default SignUpIntroContainer;
