import React, { useRef, useState } from "react";
import validator from "validator";

function useEmailAuth() {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState({
    email: "",
    emailError: "",
    password: "",
    passwordError: "",
    showPassword: false,
    error: "",
  });

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value?.replace(/\s/g, ""),
    }));
  }

  function toggleShowPassword(show: boolean) {
    setState((prev) => ({
      ...prev,
      showPassword: show,
    }));
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>, type: string) {
    const { key } = e;

    if (key === "Enter") {
      if (type === "email") {
        passwordInputRef.current?.focus();
      } else {
      }
    }
  }

  function onFocus(type: string) {
    if (type === "email" && state?.emailError) {
      setState((prev) => ({
        ...prev,
        emailError: "",
      }));
    }

    if (type === "password" && state?.passwordError) {
      setState((prev) => ({
        ...prev,
        passwordError: "",
      }));
    }
  }

  function onBlur(type: string) {
    if (type === "email") {
      if (!state?.email?.length) {
        setState((prev) => ({
          ...prev,
          emailError: "이메일 주소를 입력해주세요",
        }));
      }

      if (!validator.isEmail(state?.email)) {
        setState((prev) => ({
          ...prev,
          emailError: "올바른 이메일 주소를 입력해주세요",
        }));
      }
    } else {
      if (!state?.password?.length) {
        setState((prev) => ({
          ...prev,
          passwordError: "비밀번호를 입력해주세요",
        }));
      }

      if (state?.password?.length < 8) {
        setState((prev) => ({
          ...prev,
          passwordError: "비밀번호는 8자 이상이어야 합니다",
        }));
      }
    }
  }

  function isSubmitDisabled() {
    const isValidEmail =
      state?.email?.length &&
      validator.isEmail(state?.email) &&
      !state?.emailError;
    const isValidPassword =
      state?.password?.length &&
      state?.password?.length >= 8 &&
      !state?.passwordError;

    return !isValidEmail || !isValidPassword || !!state?.error;
  }

  async function onSubmit() {}

  return {
    refs: {
      emailInputRef,
      passwordInputRef,
    },
    models: {
      state,
    },
    operations: {
      onInputChange,
      toggleShowPassword,
      onKeyDown,
      onFocus,
      onBlur,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useEmailAuth;
